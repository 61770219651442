<template>
  <div class="container">
    <div class="d-flex justify-content-between mb-3 align-items-center">
      <h1 class="m-0 mb-2">Classes</h1>
      <router-link
        v-if="$can({ key: 'classes', expectedPermission: 'create' })"
        to="/admin/classes/create"
        class="btn btn-outline-blue btn-sm"
      >
        <icon type="plus" /> Add New Class
      </router-link>
    </div>

    <alert v-if="isLoading" class="light-shadow" />
    <alert v-if="!isLoading && classifications.length == 0" class="light-shadow"
      >No classes yet.</alert
    >
    <div
      class="card theme-card border-lg-0 table-container"
      v-if="!isLoading && classifications.length > 0"
    >
      <table class="table table-striped mb-0">
        <thead>
          <tr>
            <th>Subject</th>
            <th>User Type</th>
            <th
              v-if="$can({ key: 'classes', expectedPermission: 'edit' })"
            ></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="classification in classifications"
            :key="classification.id"
          >
            <td>{{ classification.subject }}</td>
            <td>
              {{
                classification.role_id == "3"
                  ? "Client"
                  : classification.role_id == "2"
                  ? "Provider"
                  : "-"
              }}
            </td>

            <td
              class="text-right"
              v-if="$can({ key: 'classes', expectedPermission: 'edit' })"
            >
              <router-link :to="`/admin/classes/${classification.id}`">
                <button class="btn btn-sm btn-black">Edit</button>
              </router-link>
            </td>

            <!--
              Delete Not supported in BE Yet
               <td>
              <a
                href="/"
                class="actionsSeperator"
                @click="deleteSelectedClass($event, classification.id)"
                ><icon type="trash-alt" />
              </a>
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

export default {
  name: "Classifications",
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState({
      classifications: (state) => state.classifications.data,
    }),
  },
  mounted() {
    this.getAllClassifications().then(() => {
      this.isLoading = false;
    });
  },
  methods: {
    ...mapActions({
      getAllClassifications: "classifications/get",
      deleteClass: "classifications/deleteClass",
    }),

    deleteSelectedClass: function ($event, id) {
      $event.preventDefault();
      const vm = this;
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            vm.deleteClass(id).then((deleted) => {
              if (deleted) {
                this.getAllClassifications();
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Class has been deleted.",
                  "success"
                );
              } else {
                swalWithBootstrapButtons.fire(
                  "Error!",
                  "Something went wrong...",
                  "error"
                );
              }
            });
          }
        });
    },
  },
};
</script>
